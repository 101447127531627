<template>
  <v-app>
    <nav-menu />
    <section-one />
    <section-two />
    <section-three />
    <section-four />
    <section-five />
    <section-six />
    <section-seven />
    <section-eight />
    <section-nine />
  </v-app>
</template>

<script>
import NavMenu from "components/NavMenu";
import SectionOne from "components/SectionOne";
import SectionTwo from "components/SectionTwo";
import SectionThree from "components/SectionThree";
import SectionFour from "components/SectionFour";
import SectionFive from "components/SectionFive";
import SectionSix from "components/SectionSix";
import SectionSeven from "components/SectionSeven";
import SectionEight from "components/SectionEight";
import SectionNine from "components/SectionNine";
import AOS from "aos/dist/aos";

export default {
  name: "App",
  components: {
    NavMenu,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive,
    SectionSix,
    SectionSeven,
    SectionEight,
    SectionNine,
  },
  mounted() {
    AOS.init();
  },
};
</script>
