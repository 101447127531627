<template>
  <div class="mt-15 py-15 bg-section" id="help">
    <v-container>
      <v-row justify="center">
        <v-col md="3" class="text-center text-md-left" data-aos="fade-right">
          <h1 class="fw-800">
            Ficou na dúvida se <br />
            <span class="primary--text">CardPrêmios</span> é pra <br />
            sua empresa?
          </h1>
          <v-btn
            href="#contact"
            class="fw-800 mt-5 primary text-transform-none"
            block
            depressed
            x-large
          >
            Fale com nosso time
          </v-btn>
        </v-col>
        <v-col md="4" data-aos="fade-up-left">
          <v-img src="~assets/images/presenter02.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionSeven",
};
</script>
<style scoped>
.bg-section {
  background: #fbfbfb;
}
</style>
