<template>
  <v-container class="mt-15 py-0" id="account-and-card">
    <v-row data-aos="flip-up">
      <v-col class="text-center">
        <h1 class="fw-900">Um cartão prêmio para todas as ocasiões</h1>
      </v-col>
    </v-row>
    <v-row class="col-xl-8 mx-auto">
      <v-col
        md="4"
        cols="12"
        v-for="(item, idx) in items"
        class="text-center pa-10"
        :key="idx"
        data-aos="fade-up"
      >
        <img :src="require(`../assets/icons/${item.icon}`)" alt="icon" class="black pa-3 rounded" />
        <p class="fw-800 fs18">{{ item.title }}</p>
        <p class="fs18">
          {{ item.description }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SectionSix",
  computed: {
    items() {
      return [
        {
          title: "Alimentação e refeição",
          description: `Compre em supermercados, padarias, conveniências, açougues, restaurantes e praças de alimentação.`,
          icon: "icon-cookie.svg",
        },
        {
          title: "Mobilidade",
          description: `Pague o Uber, o combustível do carro, a conta do pedágio e até estacionamentos.`,
          icon: "icon-car.svg",
        },
        {
          title: "Educação",
          description: `Use em cursos online, escolas, universidades e muito mais.`,
          icon: "icon-open-book.svg",
        },
        {
          title: "Qualidade de Vida",
          description: `Use para consultas médicas,
          odontológicas, laboratórios, farmácias,
          estúdios e academias de dança.`,
          icon: "icon-heart.svg",
        },
        {
          title: "Home office",
          description: `Pague equipamentos, papelaria, e até material escolar.`,
          icon: "icon-invoice.svg",
        },
        {
          title: "Cultura",
          description: `Compre livros, recarga de jogos, assinatura de streamings, cinemas e muito mais.`,
          icon: "icon-play.svg",
        },
      ];
    },
  },
};
</script>
<style scoped></style>
